@import url('https://fonts.googleapis.com/css2?family=Crete+Round:ital@0;1&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

:root {
  --primary-color: #DDA574;
  --black: black;
  --white: white;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Montserrat", sans-serif;
    scroll-behavior: smooth;
}


/* Basics */

.top-5{
  margin-top: 5px !important;
}

.top-15{
  margin-top: 15px !important;
}

.top-25{
  margin-top: 25px !important;
}

.top-50{
  margin-top: 50px !important;
}

.left-5{
  margin-left: 5px !important;
}

.left-15{
  margin-left: 15px !important;
}

.left-25{
  margin-left: 25px !important;
}

.left-50{
  margin-left: 50px !important;
}

.right-5{
  margin-right: 5px !important;
}

.right-15{
  margin-right: 15px !important;
}

.right-25{
  margin-right: 25px !important;
}

.right-50{
  margin-right: 50px !important;
}


.next{
display: flex !important;
align-items: center;
justify-content: space-between;
}

.together{
  display: flex;
  align-items: center;
}
.together svg{
  margin-right: 5px;
  color : var(--primary-color);
}

.color-primary{
  color: var(--primary-color);
}

.color-secondary{
  color: var(--secondary-color) !important;
}

.italic{
  font-style: italic;
}

.bold{
  font-weight: bold;
}
.crete {
  font-family: "Crete Round", serif;
  font-weight: 400;
  font-style: normal;
}

.not-found{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 88.5vh;
}

a{
  text-decoration: none !important;
  color: var(--black) !important;
}

/* Navbar */

nav{
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: var(--white);
  position: fixed;
  top: 0;
  left: 0;
  padding : 25px 50px;
  width: 100%;
  background-color: white;
  border-bottom: 3px solid var(--primary-color);
  z-index: 1000;
}

.nav-logo{
  font-size: 1.5rem;
  font-weight: bold;
  color: var(--primary-color);
}

.small-logo-title{
  font-size: 0.8rem;
  color: black !important;
  margin-left: 5px;
  margin-top: 5px;
}

.nav-logo a{
  display: flex;
  align-items: center;
}


.nav-right{
  display: flex;
  align-items: center;
  color: var(--secondary-color);
}

/* src/styles/navbar.css */

.nav-link {
  position: relative;
  transition: color 0.3s ease-in-out;
  color: black;

}

.nav-link:hover {
  color: var(--primary-color);
}

.nav-link::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: -5px;
  width: 100%;
  height: 2px;
  background-color: var(--primary-color);
  transform: scaleX(0);
  transform-origin: bottom right;
  transition: transform 0.3s ease-in-out;
}

.nav-link:hover::after {
  transform: scaleX(1);
  background-color: var(--primary-color);

  transform-origin: bottom left;
}

.nav-active {
  color: var(--primary-color) !important;
  font-weight: bold;
}


.ham-menu{
  display: none;
}

.nav-popup-title{
  font-size: 1.2rem;
  font-weight: bold;
  
}

.nav-popup-logo{
  display: flex;
  align-items: center;
  width: 32px;
  height: 32px;
  margin-right: 5px;
}

.nav-popup-up{
  display: flex;
  border-bottom: 1px solid var(--secondary-color);
  padding-bottom: 10px;
  align-items: center;
  margin-bottom: 25px;
}

.nav-popup-upper{
  display: flex;
  flex-direction: column;
  padding : 25px;
}


/*LANG*/
.language-switcher {
  position: relative;
  display: inline-block;
}

.language-switcher-btn {
  background-color: #fff; /* Change this to your desired button color */
  border: 1px solid #ddd; /* Border around the button */
  padding: 8px 12px;

  cursor: pointer;
  border-radius: 4px;
  display: flex;
  align-items: center;
}

.language-dropdown {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 100%;
  left: 0;
  background-color: #fff; /* Background color of the dropdown */
  border: 1px solid #ddd; /* Border around the dropdown */
  border-radius: 4px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Shadow for better visibility */
  z-index: 1000;
}

.language-option {
  padding: 8px 12px;
  cursor: pointer;
  display: flex;
  align-items: center;
  transition: background-color 0.2s;
}

.language-option:hover {
  background-color: #f0f0f0; /* Change this to your desired hover color */
}

.flag {
  display: flex;
  margin-right: 8px;
}

.flag-name{
  font-size: 0.9rem;
  margin-left: 5px;
}


/* HOMEPAGE */
.landing-page-container {
  height: 100vh;
  width: 100%;
  position: relative; /* Create relative context for children */
}

.landing-page-img-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1; /* Set the image behind the text */
}

.landing-page-img-container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* Gradient Overlay Effect */
.landing-page-img-container::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.34) 25%, rgba(0, 0, 0, 0) 100%);
  z-index: 2; /* Layer the gradient on top of the image */
}

.landing-page-information-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100vh;
  padding : 0 50px;
  position: relative;
  z-index: 3; /* Ensure text is on top of the image and gradient */
  color: white; /* Ensure text is visible */
}

.landing-page-title {
  font-size: 3rem;
  font-weight: bold;
  width: 45%;
}

.landing-page-buttons {
  margin-top: 20px;
  position: absolute;
  bottom : 150px;
  z-index: 3;
  left: 50px;
}

.button-primary {
  background-color: var(--primary-color);
  border: 3px solid var(--primary-color);
  color: white !important;
  padding: 15px 40px;
  margin-right: 10px;
  cursor: pointer;
  font-weight: bold;
  font-size: 0.9em;
  transition: background-color 0.3s ease;
}

.button-primary:hover {
  background-color: #c88f5b;
  border : 3px solid #c88f5b;
}

.button-secondary {
  background-color: var(--white);
  color: var(--primary-color) !important;
  padding: 15px 40px;
  border: 3px solid var(--white);
  font-weight: bold;
  cursor: pointer;
  font-size: 0.9em;

  transition: background-color 0.3s ease;
}

.button-secondary:hover {
  background-color: #dddddd;
  border : 3px solid #dddddd;
}

.button-third{
  background-color: var(--white);
  color: var(--primary-color) !important;
  padding: 15px 40px;
  border: 3px solid var(--primary-color);
  font-weight: bold;
  cursor: pointer;
  font-size: 0.9em;

  transition: background-color 0.3s ease;
}

.button-third:hover {
  background-color: #e8e8e8;
  border : 3px solid #e8e8e8;
}

.page-divider {
  width: 100%;
  height: 20px;
  background-color: var(--primary-color);
}



/* BLOCK */
.block-container{
  display: flex;
  padding: 75px 50px; 
  justify-content: space-between;
}

.block-information{
  width: 45%;
}

.block-title{
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 30px;
}

.block-text{
  margin-top: 20px;
}

.block-img-container{
  width: 40%;
}
.block-img-container img{
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.block-item{
  margin-top: 15px;
  display: flex;
}

.star{
  font-weight: bold;
  color: var(--primary-color);
  font-size: 2rem;
  margin-right: 15px;
}


/* SLIDER */
.slider-container {
  display: flex;
  background-color: var(--white);
  padding: 10px 0 20px 0;
  overflow: hidden; /* Hide the images outside the visible area */
  width: 100%; /* Set the visible width */
  overflow-x: auto; /* Allow horizontal scrolling */
  white-space: nowrap; /* Prevent images from wrapping */
  scroll-behavior: smooth; /* Smooth scroll */
}

::-webkit-scrollbar{width:5px;}
::-webkit-scrollbar-thumb{background-color:var(--primary-color);}

.slider-row {
  display: flex;
  animation: slide 150s linear infinite; /* Infinite animation */
}

.slider-row img {
  width: 750px; /* Width of each image */
  height: auto;
  margin-right: 30px;
}

@keyframes slide {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-2000px); /* Adjust based on the total width of images */
  }
}


/* FOOTER */
footer {
  background-color: var(--primary-color);
  color: white;
  padding: 20px;
}

.footer-part-title{
  font-size: 1.5rem;
  display: flex;
  justify-content: center;
}

.footer-socials{
  display: flex;
  margin-top: 5px;
  justify-content: center;
}

.footer-socials a{
  margin: 5px;
  margin-top: 0 !important;
}

.footer-socials a svg{
  color: white;
}


/* PAGE */
.page-container{
  padding: 150px 50px 50px 50px;
}

/* AROUND */
.around-title{
  font-size: 1.2rem;
  font-weight: bold;
  margin-top: 50px;
  margin-bottom: 30px;
}
.around-container{
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
}

.around-card{
  display: flex;
  flex-direction: column;
  background-color: var(--white);
 
  border-radius: 0px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.around-card-image{
  width: 100%;
  height: 300px;
  overflow: hidden;
  margin-bottom: 10px;
}

.around-card-image img{
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.around-card-information{
  padding: 20px;
  display: flex;
  flex-direction: column;
}

.around-card-title{
  font-size: 1rem;
  font-weight: bold;
  margin-bottom: 10px;
  margin-top : 10px;
}

.around-card-text{
  font-size: 0.9rem;
  margin-bottom: 10px;
}

.around-card-distance{
  font-size: 0.9rem;
  font-style: italic;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
}

.around-card-distance svg{
  margin-right: 5px;
  color: var(--primary-color);
}

.around-card-moreInfo{
  font-size: 0.9rem;
  font-weight: bold;
  color: var(--primary-color) !important;
  cursor: pointer;
}

.around-card:hover {
  transform: translateY(-10px);
}



/* Contact */
form{
  display: flex;
  flex-direction: column;
}
.rijForm{
  display: flex; 
  width: 100%;
  justify-content: space-between;
}
.colomForm{
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-right: 20px;
 
  margin-bottom: 20px;
}
.inputlabel{
  font-weight: 400;
}
.inputField{
  width: 100%;
  height: 5vh;
  border-radius: 5px;
  background-color: #f2f2f2;
  border: 0;
  padding-left: 1em;
  margin-top: 1em;
  font-size: 1em;
  font-weight: 400;
  transition: 0.5s;
  outline: none;
}
.rijFormFull{
  width: 100%;
  padding-right: 20px;
}
.inputFieldBig{
  width: 100%;
  height: 25vh;
  border-radius: 5px;
  background-color: #f2f2f2;
  border: 0;
  padding : 1em;
  margin-top: 1em;
  font-size: 1em;
  font-weight: 400;
  resize: none;
  transition: 0.5s;
  outline: none;
}
.inputFieldcat{
  width: 100%;
  height: 5vh;
  border-radius: 5px;
  background-color: #f2f2f2;
  border: 0;
  padding-left: 1em;
  margin-top: 1em;
  font-size: 1em;
  font-weight: 400;
  transition: 0.5s;
  outline: none;
}
.btnadd{
  background-color: var(--primary-color);
  border-radius: 5px;
  color: var(--white) !important;
  padding: 1em 5em;
  font-weight: 700;
  border : 0;
  cursor: pointer;
  transition: 0.5s;
  margin-top: 20px;
  
}
.inputFieldBig:focus, .inputField:focus{
  
  border : 1px solid var(--primary-color);
}

.green{
  margin-top: 5px;
  padding : 20px 20px;
  display: flex;
  align-items: center;
  background-color: #3ccb6d;
  color: white;
  border-radius: 5px;
}
.red{
  margin-top: 5px;
  padding : 20px 20px;
  display: flex;
  align-items: center;
  background-color: #ff3f5f;
  color: white;
  border-radius: 5px;

}
.red svg, .green svg{
  margin-right: 10px;
  color: white;
  font-size: 1.5em;
}



/* Mobile */
@media (max-width: 768px) {
  
  nav {
    padding: 15px 25px;
  }

  .nav-right{
    display: none;
  }


.nav-popup {
  position: relative;
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0;
  right: -100vw;
  width: 100vw;
  height: 100%;
  background-color: #fff;
  color: #333;
  transition: right 0.3s ease;
  border-left: 1px solid #333;
  z-index: 1 !important;
}


.nav-popup.open {
  right: 0;
}

.nav-popup  {
  list-style: none;
}

.nav-menu-icon {
  position: relative;
  font-size: 1.5rem;
  cursor: pointer;
  z-index: 2 !important;
}

.nav-popup .nav-link {
  margin: 1rem 0;
  margin-left: 0px !important;
}

.nav-popup .nav-link  {
  text-decoration: none;
  color: #333;
}

.ham-menu{
  display: block;
}

.language-switcher{
  margin-right: 8px;
  margin-bottom: 5px;
}


.landing-page-information-container{
  padding: 0 25px;
}

.landing-page-title{
  font-size: 3rem;
  width: 100%;
  text-align: center;
}
.landing-page-container{
  position: relative;
  width: 100%; /* Full width */
  height: 100vh; /* Full height for demo purposes */
}
/*CENTER*/
.landing-page-buttons {
  position: absolute !important;
  bottom: 150px; /* Distance from the bottom */
  left: 50%; /* Start from the center of the parent */
  display: flex; /* Use flexbox to align buttons */
  transform: translateX(-50%) !important; /* Shift left by 50% of its own width */
  width: 100%; /* Full width */
  align-items: center;
  justify-content: center;
}

.block-container{
  flex-direction: column;
  padding: 50px 25px;
}
.block-information{
  width: 100%;
  margin-bottom: 25px;
}
.button-group{
  display: flex;
  flex-direction: column;
  align-items: center;
}
.button-group a{
  width: 100%;
  text-align: center;
  margin-right: 0;  
}
.button-group a:last-child{
  margin-top: 10px !important; 
}

.block-img-container{
  width: 100%;
}

.slider-container{
  padding: 10px 0 20px 0;
}
.slider-row{
  animation: slide 100s linear infinite;
}

.slider-row img{
  width: 100%;
  margin-right: 5px;
}
.address{
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.page-container{
  padding: 100px 25px 25px 25px;
}
.page-container .block-information .block-title{
  font-size: 1.5rem;
  margin-bottom: 10px;
}
.around-container{
  grid-template-columns: repeat(1, 1fr);
}
form{
  min-height: fit-content;
}

.rijForm{
  flex-direction: column;
  width: 100%;
}
.colomForm{
  width: 100%;
  margin-right: 0;
  margin-left: 0;
  margin-bottom: 20px;
}
.inputFieldcat{
  height: 8vh;
}
.inputField{
  padding : 2em 1em;
}
.rijFormFull{
  width: 100%;
  padding-left: 0;
  padding-right: 0;
}
.inputFieldBig{
  padding : 1em 1em;
  height:  30vh;
}
.btnadd{
  width: 100%;
  text-align: center;
  padding : 2em 1em;
}
.red,.green{
  padding: 10px 10px;
  flex-direction: column;
  text-align: center;
}
.red svg, .green svg{
  margin-right: 0;
  margin-bottom: 15px;
}
}

@media (max-height: 768px) {
  .landing-page-title {
    font-size: 2.5rem;
  }

  .landing-page-buttons {
    bottom: 100px;
  }
}
